<template>
    <div class="container title fw-normal text-dark mb-0 ls-05 inline-block">
        <el-row class="header-row " type="flex">
            <el-col :span="2" class="align-center">
                <el-button :underline="false" size="mini" icon="el-icon-back" @click="goBack"
                    type="plain">Back</el-button>
            </el-col>
            <el-col :span="8">
                <div class="icon-text pl-1">
                    <p class="title" style="font-size:16px; margin-left:-20px">Bug Tracker</p>
                </div>
            </el-col>
            <el-col :span="12" class="align-center">
                <p class="title " style="font-size:16px">Application Development Steps</p>
            </el-col>
        </el-row>
        <el-row id="body-row">
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/FormTemplates.svg" alt="FormTemplate">
                    <p class="title">Form Templates</p>
                    <p class="description">A Form Template is pre-defined layout that defines the fields needed to collect
                        specific information from users.</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/Entity.svg" alt="Entity">
                    <p class="title">Entities</p>
                    <p class="description">A Entity data collection refers to the process of gathering and recording data
                        about a person or objects</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/Filters.svg" alt="Entity Filters">
                    <p class="title">Entity Filters</p>
                    <p class="description">A Filters are tools used to sort,refine and limit data based on specific
                        criteria.</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/Roles.svg" alt="Roles">
                    <p class="title">Role Management</p>
                    <p class="description">Roles refers to the specific responsibilities and permissions assigned to
                        individuals within an organization or system.</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/Groups.svg" alt="Groups">
                    <p class="title">Groups</p>
                    <p class="description">A group is a collection of individuals who share a common identity and interact
                        with each other.</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/CustomDashboard.svg" alt="Custom Dashboard">
                    <p class="title">Custom Dashboard</p>
                    <p class="description">A custom dashboard is a personalized user interface that displays relevant data
                        from different sources.</p>
                </el-card>
            </el-col>
            <el-col :span="8" class="block">
                <el-card class="box-card">
                    <img src="../../assets/appIcons/MenuManagement.svg" alt="Menu Management">
                    <p class="title">Menu Management</p>
                    <p class="description">Menu Management is the process of organizing and maintaining a menu in a software
                        application.</p>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'step-container',
    methods: {
        goBack() {
        }
    }
}
</script>

<style>
.el-card {
    box-shadow: none !important;
    cursor: pointer;
}

.block{
    width: 372px;
    height: 160px;
    margin: 20px 20px 0 0;
}

.el-card__body {
    padding: 20px;
    padding-right: 10px;
}

#body-row {
    margin-left: 130px;
}

img {
    padding: 5px;
    background: rgba(203, 214, 226, 0.4);
    border-radius: 3px;
}

.title {
    font-weight: 400;
    font-size: 18px;
}

.description {
    font-size: 12px;
}

.header-row {
    border-bottom: 1px solid #CBD6E2;
    padding-left: 30px;
    height: 80px;
    display: flex;
  align-items: center;
}
el-button{
width: 1400px;
padding:0;
}
</style>

